<template>
  <div class="mytabs">
    <div>

    </div>
    <div class="select-card card-title">
      <a class="btn-close-history" @click="$router.go(-1)">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </a>
      <div class="title" v-text="$t('history.rent')"></div>
    </div>
    <div>
      <div class="text-center">
        <BSpinner id="loading-spinner" v-if="spinner" />
      </div>
      <div class="select-card subscribe-margin" v-for="result in results" :key="result.id">

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket" class="fa fa-rotate-90" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Retirada:
              </div>
              <div v-if="result.take_point && result.take_point.name && result.take_point.address" class="flex-history-text">
                {{ result.take_point.name }}, {{ result.take_point.address }}
              </div>
              <div class="flex-history-text">
                {{ result.take_date }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-clock-rotate-left" class="fa mirror" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Tempo com a bateria:
              </div>
              <div class="flex-history-text">
                {{ this.rentTime(result) }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" class="fa" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Devolução:
              </div>
              <div class="flex-history-text">
                {{  this.returnBattery(result) }}
              </div>
              <div v-if="result.status !== 2" class="flex-history-text">
                {{ result.return_date }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-dollar-sign" class="fa" />
            </div>
            <div class="flex-history-description">
              <div v-if="result.status === 2" class="flex-history-title">
                Reserva de saldo:
              </div>
              <div v-else class="flex-history-title">
                Pagamento:
              </div>
              <span>
                <span
                  class="flex-history-text"
                  :class="{
                    'line-through': isRefunded(result)
                    }"
                  style="display: inline-block" v-html="price(result) + refundPrice(result)"
                >
                </span>&nbsp
                <span
                  v-if="isRefunded(result)"
                  class="flex-history-text"
                  style="display: inline-block"
                >
                  {{ ' (devolvido)' }}
                </span>
              </span>
              
              <div class="flex-history-text">
                {{ this.paymentType(result) }} 
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-star" class="fa" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Classificação:
              </div>
              <div v-if="result.rating" class="flex-history-star">
                <div v-for="star in 5" :key="star">
                  <font-awesome-icon icon="fa-solid fa-star"
                    :class="[star <= result.rating ? 'fa-minor flex-history-star-on' : 'fa-minor flex-history-star-off']"
                    class="fa " />
                </div>
              </div>
              <div v-else class="flex-history-text">
                {{ 'Sem classificação' }}
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
    <div class="text-center">
      <b-button v-if="next_page" @click="get_history" variant="primary">
        <div v-text="$t('history.load_more')"></div>
      </b-button>
    </div>
  </div>
</template>

<script>

import API from '@/services/api'
import sendGtag from '@/services/sendGtags'
import handleError from '@/services/handleError'

const PRE_PAYMENY_APROVED = 2
const PRE_PAYMENT_REFUNDED = 6

const CREDIT_CARD_PAYMENT = 1
const PIX_PAYMENT = 3

const GTAG_HISTORY = 'history'

export default {
  name: 'history',
  components: {},
  props: [],
  data() {
    return {
      results: [],
      history_a: [],
      next_page: null,
      spinner: true,
      history_blocks: [],
      lineThrough: false
    }
  },
  mounted: async function () {
    let c = sessionStorage.getItem('campaign')
    if (c) {
      this.campaign = c
    }

    sendGtag(GTAG_HISTORY, {}, true)

    await this.get_history()
  },
  methods: {
    isRefunded (result) {
      if (result.payment && (result.payment.status === 'Approved' || result.payment.status === 'Partial')) {
        return false
      }
      if (result.payment && result.payment.status !== 'Approved' && result.payment.status !== null) {
        return true
      } else if (result.last_pre_payment && result.last_pre_payment.status === PRE_PAYMENT_REFUNDED) {
        return true
      }
      return false
    },
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate: function (date) {
      return (
        [
          this.padTo2Digits(date.getDate()),
          date.toLocaleString('default', { month: 'short' }),
          date.getFullYear()
        ].join(' de ') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes())
        ].join(':')
      )
    },
    get_history: async function async() {
      let url = 'transaction/history'
      if (this.results.length > 0 && this.next_page) {
        url = this.next_page
      }

      try {
        const response = await API.get(url)

        this.history_a = response.data
        this.next_page = this.history_a.meta.next_page
        let results_temp = this.history_a.results
        if (results_temp && results_temp.length > 0) {
          let results = this.modify_api_response(results_temp)
          if (this.results.length > 0 && this.next_page) {
            this.results.push(...results)
          } else {
            this.results = results
          }
        }
        this.spinner = false

      } catch (error) {
        handleError(error)
      }
    },
    modify_api_response: function (arr) {
      arr.forEach((element) => {
        element.take_date = this.formatDate(new Date(element.take_time * 1000))
        if (element.plan_name == '') {
          element.no_plan = true
        }
        if (element.return_time === 0) {
          element.elapsed_time = 'DEVOLUÇÃO PENDENTE'
        } else {
          element.return_date = this.formatDate(new Date(element.return_time * 1000))
          var time = element.elapsed_time
          var hours = Math.floor(time / 3600)
          var min = Math.floor((time - hours * 3600) / 60)

          var hours_pad = this.padTo2Digits(hours)
          var min_pad = this.padTo2Digits(min)

          var hours_str = hours_pad != '00' ? hours_pad + ' h ' + 'e ' : ''
          var min_str = min_pad != '00' ? min_pad + ' min' + ' e ' : ''
          element.elapsed_time = hours_str + min_str
        }
      })
      return arr
    },
    price (result) {
      if (result.free_time_type === "Signature"){
        return "Grátis pela assinatura"
      }
      const isCreditCardTransaction = result.payment && result.payment.type === "credit_card"
      const isTransactionCaptured = result.payment && ['Approved', 'Captured'].includes(result.payment.status)
      const isPrePaymentCaptured = result.last_pre_payment && result.last_pre_payment.status === PRE_PAYMENY_APROVED
      const isPixPayment = result.payment && result.payment.type === "pix"
      const isPixPrePayment = result.last_pre_payment && result.last_pre_payment.type === PIX_PAYMENT
      const isPartialCaptured = result.payment && result.payment.status === 'Partial'
      const hasAmount = result.payment && result.payment.amount
      const hasAmountPrePayment = result.last_pre_payment && result.last_pre_payment.amount

      const isCreditCardPrePaymentRefunded = result.last_pre_payment && result.last_pre_payment.status === PRE_PAYMENT_REFUNDED

      const isPrePaymentAuthorized = result && result.last_pre_payment && result.last_pre_payment.status === 11

      if ((isCreditCardTransaction || isPixPayment ) && (isTransactionCaptured || isPartialCaptured ) && hasAmount) {
        return "R$ " + parseFloat(result.payment.amount).toFixed(2).replace('.', ',')
      }

      if ((isCreditCardPrePaymentRefunded || isPixPrePayment || isPrePaymentCaptured) && hasAmountPrePayment) {
        return `R$ ${parseFloat(result.last_pre_payment.amount).toFixed(2).replace('.', ',')}`
      }

      if (isPrePaymentAuthorized && hasAmountPrePayment) {
        return `R$ ${parseFloat(result.last_pre_payment.amount).toFixed(2).replace('.', ',')} (caução)`
      }
    },
    refundPrice (result) {
      const isPaymentApproved = result.payment && result.payment.status === 'Approved'
      const paymentAmount = result.payment && result.payment.amount
      const prePaymentAmount = result.last_pre_payment && result.last_pre_payment.amount
      const isPartialRefund = paymentAmount && prePaymentAmount && prePaymentAmount > paymentAmount
      if (isPaymentApproved && isPartialRefund) {
        const prePaymentFormatted = parseFloat(prePaymentAmount).toFixed(2).replace('.', ',');
        const refundAmountFormatted = parseFloat(prePaymentAmount - paymentAmount).toFixed(2).replace('.', ',');
        return ` (R$ ${prePaymentFormatted} caução e R$ <s>${refundAmountFormatted}</s> devolvido)`;
      }
      return '';
    },
    paymentType (result) {
      const isCreditCardPayment = result.payment && result.payment.type === "credit_card"
      const isPixPayment = result.payment && result.payment.type === "pix"
      const isCreditCardPrePayment = result.last_pre_payment && result.last_pre_payment.type === CREDIT_CARD_PAYMENT
      const isPixPrePayment = result.last_pre_payment && result.last_pre_payment.type === PIX_PAYMENT
      if (isCreditCardPayment || isCreditCardPrePayment) {
        return`Cartão de crédito final: ${result.credit_card.number}`
      } else if (isPixPayment || isPixPrePayment) {
        return`PIX`
      }
    },
    rentTime(result) {
      return result.status === PRE_PAYMENY_APROVED
        ? result.cron_time
        : result.rent_time.all
    },
    returnBattery(result) {
      return result.status !== PRE_PAYMENY_APROVED
        ? `${result.return_point.name }, ${ result.return_point.address }`
        : "Bateria com o cliente"
    }
  }
}


</script>

<style scoped lang="scss">
@import '@/main.scss';

@font-face {
  font-family: main;
  src: url('@/assets/fonts/main.otf');
}

.card-title {
  margin: auto;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  flex: 1.6;
}

.btn-close-history {
  color: #666;
  font-size: 30px;
  z-index: 99999;
  cursor: pointer;
  margin-left: 10px;
  flex: 1;
}

#loading-spinner {
  color: $color-energy-orange !important;
}

.mytabs {
  overflow: scroll;
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.flex-history-element {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
}

.flex-history-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: 80px;
}

.flex-history-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.flex-history-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px !important;
  padding: 0px !important;
}

.flex-history-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: gray;
  font-size: large;
  margin: 0px !important;
  padding: 0px !important;
}

.flex-history-star {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px
}

.flex-history-star-on {
  color: rgb(107, 107, 107);
}

.flex-history-star-off {
  color: rgb(202, 202, 202);
}

.mirror {
  transform: scaleX(-1);
}

.subscribe-margin {
  margin-bottom: 15px !important;
}

.fa {
  font-size: 30px;
  margin-bottom: 1px;
  margin-right: 2px;
}

.fa-minor {
  font-size: 30px;
}

// .rating-text {
//   position: absolute;
//   font-size: 24px;
//   /* ajuste o tamanho da fonte para o seu gosto */
//   z-index: 2;
//   color: white;
// }

.line-through {
  text-decoration: line-through;
}

</style>