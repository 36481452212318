import * as Sentry from '@sentry/vue'
import router from '@/router'

export default function handleError(error) {
    Sentry.captureException(`${JSON.stringify(error)}`)
    console.log(error)
    if (error && error.status && error.status === 401) {
        router.push({
            name: 'logout',
            params: {}
        })
    }
}