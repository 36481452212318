import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USBC} from '@/constants.js'

export default function getPropsMiniComponents(component) {
  switch (component) {
    case 'map':
      return {
        subText: this.$t('login.find_us'),
        arrowDown: this.mapArrowDown
      }
    case 'managePayment':
      if (this.user && this.user.default_card && this.user.default_card.number.length === 4) {
        return {
          mainText: this.$t('manage_payment.card.title'),
          subText: this.$t('home.take.card_ending') + ' ' + (this.user.default_card && this.user.default_card.number ? this.user.default_card.number : ''),
          allowEdit: !(this.showPBox || !this.allowEdit),
          arrowDown: !(this.selectedBigBoxComponent && this.selectedBigBoxComponent.reference === 'managePayment')
        }
      } else if (this.user && this.user.pix_identification) {
        return {
          mainText: this.$t('manage_payment.pix.title'),
          subText: this.user && this.user.pix_identification && this.user.pix_identification.name 
            ? this.user.pix_identification.name 
            : '',
          allowEdit: !(this.showPBox || !this.allowEdit),
        }
      } else {
        return {
          mainText: this.$t('manage_payment.none.title'),
          subText: this.$t('manage_payment.none.subtitle'),
          allowEdit: true
        }
      }
    case 'cableType':
      return {
        subText: defaultCableText(this.cableType),
        termsText: this.$t('home.take.user_cable_notice_2'),
        allowEdit: !(this.showPBox || !this.allowEdit),
        arrowDown: !(this.selectedBigBoxComponent && this.selectedBigBoxComponent.reference === 'selectCableType')
      }
    case 'token':
      return {
        mainText: this.$t('token_selection.main_text'),
        subText: this.pointName + ' - ' + this.token,
        allowEdit: !(this.showPBox || !this.allowEdit),
      }
    default:
      return console.log('Component not found')
  }
}

function defaultCableText(cableType) {
  if (cableType === CABLE_TYPE_LIGHTNING) {
    return 'Apple Lightning'
  } else if (cableType === CABLE_TYPE_MICRO) {
    return 'Micro USB'
  } else if (cableType === CABLE_TYPE_USBC) {
    return 'USB-C'
  }
  return null
}
