export default function getPointId() {
    const pointIdTokenSelection = sessionStorage.getItem('point_id_token_selection')
    if (pointIdTokenSelection) {
        return { id: pointIdTokenSelection, type: 'token_selection' }
    }
    const pointIdQr = sessionStorage.getItem('point_id_qr')
    if (pointIdQr) {
        return { id: pointIdQr, type: 'qr_code' }
    }
    const pointIdGeolocation = sessionStorage.getItem('point_id_geolocation')
    if (pointIdGeolocation) {
        return { id: pointIdGeolocation, type: 'geolocation' }
    }
    return { id: null, type: null }
  }