import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function getLastTransaction () {
    try {
        const response = await API.get(
            'transaction/history?page=1&items_per_page=1'
        )
        if (response && response.data && response.data.last_transaction) {
            const lastTransaction = response.data.last_transaction
            console.log(lastTransaction)
  
            let now = new Date().getTime()
            let past = lastTransaction.take_time * 1000
            let freeTime = lastTransaction.free_time * 1000
  
            if (now - past > freeTime) {
              lastTransaction['on_free_time'] = false
            } else {
              lastTransaction['on_free_time'] = true
            }

            return lastTransaction
        }
    } catch (error) {
        handleError(error)
    }
}