import getPointId from "@/services/getPointId"
import API from "@/services/api"
import * as Sentry from "@sentry/vue"
import handleError from "@/services/handleError"

let methodUsedToPointId
let newDeviceSaved = false

export default async function saveBatteryStatus(user, position, uuid, cableType) {
  try {
    const device = user.devices.find((device) => device.uuid === uuid)
    const { id, type } = getPointId()

    if (methodUsedToPointId && methodUsedToPointId !== type && methodUsedToPointId != 'geolocation') {
      return
    }
    methodUsedToPointId = type

    if (
      !device || device &&
      (!device.point_ids.some((pointId) => pointId !== null)) &&
      !newDeviceSaved
    ) {
      newDeviceSaved = await sendBatteryStatus(uuid, position, id, cableType, user)
      return
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}


async function sendBatteryStatus(deviceId, position, pointId, cableType, user) {

  let batteryInfo = null
  batteryInfo = await getBatteryStatus()

  let params = {
    action: 'registration',
  }

  if (deviceId) {
    params.device_id = deviceId
  }

  if (pointId) {
    params.point_id = pointId
  }

  if (batteryInfo && batteryInfo.level) {
    params.charge = batteryInfo.level
  }
  if (position && position.lat && position.lng) {
    params.lat = position.lat
    params.long = position.lng
  }
  if (position && position.precision) {
    params.precision = position.precision
  }
  if (cableType) {
    params.cable_type = cableType
  }
  try {
    const response = await API.put('battery/save_history', params)
    if (response && response.data && response.data.success) {
      user.devices = user.devices.map((device) => {
        if (device.uuid === deviceId) {
          device.action2_count += 1
          device.point_ids.push(pointId)
        }
        return device
      })
    } else {
      let error = ""
      if (response) {
        error = `Failed to save battery status: ${response}`
      } else {
        error = 'Failed to save battery status'
      }
      Sentry.captureException(error)
      return
    }
    return response.data.success
  } catch (error) {
    handleError(error)
  }
}


async function getBatteryStatus() {
  if ('getBattery' in navigator) {
    try {
      const battery = await navigator.getBattery();

      let batteryInfo = {
        level: 0,
        charging: false,
        chargingTime: 0,
        dischargingTime: 0
      }

      if (battery.level) { batteryInfo.level = Number(battery.level * 100) }
      if (battery.charging) { batteryInfo.charging = battery.charging }
      if (battery.chargingTime) { batteryInfo.chargingTime = battery.chargingTime }
      if (battery.dischargingTime) { batteryInfo.dischargingTime = battery.dischargingTime }

      return batteryInfo;

    } catch (error) {
      handleError(`Failed to retrieve battery status: ${error}`)
      return null
    }
  }
  return null
}
