import * as Sentry from '@sentry/vue'

export default async function setupOneSignal(email) {
  try {
    // Check if OneSignal external ID is already set
    OneSignal.Notifications.requestPermission()
    const currentExternalId = await OneSignal.User.externalId
    if (currentExternalId !== email) {
      // Only set external ID if it's not already set to this email
      await OneSignal.login(email)
      console.log('OneSignal external ID set/updated for:', email)
    } else {
      console.log('OneSignal external ID already set correctly')
    }
  } catch (error) {
    console.error('Failed to setup OneSignal:', error)
    Sentry.captureException('OneSignal setup error: ' + error)
  }
}
