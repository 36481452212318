<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <b-card
      class="select-card text-center"
      :class="{ 'border-danger': success_val !== null }"
    >
      <Transition
        name="fade-element"
        mode="out-in"
      >
        <h5
          key="image"
          class="page-title"
          v-text="$t('home.take.add_card')"
        />
      </Transition>
      <Transition
        name="fade-element"
        mode="out-in"
      >
        <p
          key="image"
          class="page-text"
          v-text="$t('home.take.no_charge')"
        />
      </Transition>

      <Transition
        name="fade-element"
        mode="out-in"
      >
        <BContainer
          key="text"
          class="bv-example-row"
        >
          <BRow>
            <BCol>
              <b-form-group>
                <b-form-input
                  id="card_number_input"
                  v-model="card.number"
                  v-mask="'#### #### #### ####'"
                  type="tel"
                  :placeholder="$t('home.take.card_number')"
                  :class="{ 'border-danger': errors.number }"
                />
              </b-form-group>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <b-form-group>
                <b-form-input
                  id="expiration_card_date_input"
                  v-model="card.expiration_date"
                  v-mask="'##/##'"
                  type="text"
                  :placeholder="$t('home.take.valid_through')"
                  :class="{ 'border-danger': errors.expiration_date }"
                />
              </b-form-group>
            </BCol>
            <BCol>
              <b-form-group>
                <b-form-input
                  id="cvs_card_input"
                  v-model="card.cvs"
                  v-mask="'####'"
                  type="text"
                  placeholder="CVS"
                  :class="{ 'border-danger': errors.cvs }"
                />
              </b-form-group>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <b-form-group>
                <b-form-input
                  id="card_owner_name_input"
                  v-model="card.name"
                  type="text"
                  :placeholder="$t('home.take.name')"
                  :class="{ 'border-danger': errors.name }"
                />
              </b-form-group>
            </BCol>
          </BRow>
          <BRow
            v-if="loading"
            class="d-flex justify-content-center"
          >
            <b-spinner id="loading-spinner" />
          </BRow>
          <BRow>
            <BCol>
              <b-form-group class="text-center">
                <button
                  class="btn btn-md btn-primary mt-3 mb-3"
                  :disabled="loading || success_val === false"
                  @click="registerCreditCard"
                  v-text="$t('home.take.save_credit_card')"
                />
              </b-form-group>
            </BCol>
          </BRow>
          <BRow v-if="success_val !== null">
            <BCol>
              <div class="message-fail">
                <h5>{{ message }}</h5>
              </div>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <b-form-group class="text-center">
                <button
                  class="btn btn-md mb-3"
                  :disabled="loading"
                  @click="back"
                  style="text-decoration: underline;"
                  v-text="$t('credit_card_registration.button')"
                />
              </b-form-group>
            </BCol>
          </BRow>
        </BContainer>
      </Transition>
    </b-card>
  </Transition>
</template>

<script>
import API from '@/services/api'
import sendGtag from '@/services/sendGtags'

const GTAG_PAYMENT_METHOD = 'payment_method'

export default {
  name: 'CreditCardRegistration',
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  emits: ['user', 'back'],
  data() {
    return {
      card: {
        number: '',
        expiration_date: '',
        cvs: '',
        name: ''
      },
      message: '',
      success_val: null,
      errors: {
        number: false,
        expiration_date: false,
        cvs: false,
        name: false
      },
      loading: false
    }
  },
  watch: {
    card: {
      handler() {
        this.success_val = null
        this.message = ''
        this.errors = ''
      },
      deep: true
    }
  },
  methods: {
    async registerCreditCard() {
      this.loading = true
      try {
        const response = await API.post('credit_card/register', {
          name: this.card.name.toUpperCase(),
          number: this.card.number.replace(/\D+/g, ''),
          cvs: this.card.cvs,
          expiration_date: this.card.expiration_date
        })

        if (response && response.data && response.data.success) {
          const creditCardId = response.data.credit_card_id
          let user = this.user
          const flag = this.getFlagTemporary(this.card.number)
          this.card.number = this.card.number.slice(-4)
          user.credit_cards.push({ ...this.card, id: creditCardId, default: true, flag: flag })
          user.credit_cards = user.credit_cards.map((creditCard) => {
            if (creditCard.id !== creditCardId) {
              creditCard.default = false
            }
            return creditCard
          })
          user.default_card = user.credit_cards.find((creditCard) => creditCard.default)

          let params = {
            payment_type: 'credit_card',
            user_id: this.user.id
          }
          sendGtag(GTAG_PAYMENT_METHOD, params, false)

          this.$emit('user', user)
          this.cleanCard()
        } else if (response && response.data && !response.data.success) {
          this.success_val = false
          this.message = response.data.msg
          if (!response.data.field) return

          this.errors = { number: false, expiration_date: false, cvs: false, name: false }
          if (response.data.field === 'number') this.errors.number = true
          if (response.data.field === 'expiration_date') this.errors.expiration_date = true
          if (response.data.field === 'cvv') this.errors.cvs = true
          if (response.data.field === 'name') this.errors.name = true
        } else {
          this.success_val = false
          this.message = 'Erro ao registrar cartão. Tente novamente.'
        }
      } catch (error) {
        console.log(error)
        this.success_val = false
        this.message = 'Erro ao registrar cartão. Tente novamente.'
      } finally {
        this.loading = false
      }
    },
    cleanCard() {
      this.card = {
        number: '',
        expiration_date: '',
        cvs: '',
        name: ''
      }
    },
    back() {
      this.$emit('back')
    },
    getFlagTemporary(creditCardNumber) {
      const flagMap = {
        '4': 'Visa',
        '51': 'Mastercard',
        '34': 'American Express',
        '37': 'American Express',
        '6011': 'Discover',
        '64': 'Discover',
        '65': 'Discover',
      };

      const flag = Object.keys(flagMap).find(key => creditCardNumber.startsWith(key));

      if (flag) {
        return flagMap[flag];
      }
      return '';
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.form-control {
  margin: 6px 0;
  padding: 10px;
  border: 1px #000 solid;

  &::placeholder {
    color: black;
    opacity: 0.5;
  }
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
}

#loading-spinner {
  display: block;
  margin: 20px auto;
  color: $color-energy-orange;
}

.btn-pix {
  color: $color-energy-blue !important;
  background-color: white !important;
}
</style>
