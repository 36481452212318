<template>
    <b-card class="select-card text-center">
        <div
            class="d-flex flex-column justify-content-center align-items-center">
            <p class="page-title h4 text-uppercase" v-text="$t('select_payment_method.title')" />
            <p class="page-text fw-normal" v-text="$t('select_payment_method.subtitle')" />
        </div>
        
        <div style="max-width: 95%;" class="container">
            <div class="row">
                <!-- Coluna de texto à esquerda -->
                <div class="col-8 p-2 d-flex justify-content-center align-items-center" style="font-size: 15px;">
                    <span v-text="$t('select_payment_method.type_1') + ':'" />
                </div>
                <!-- Botão à direita -->
                <div class="col-4 p-2 d-flex justify-content-center align-items-center">
                    <BButton variant="primary" class="btn" @click="creditCardLink" v-text="$t('select_payment_method.register')" />
                </div>
            </div>
            <div class="row">
                <div class="col-8 p-2 d-flex justify-content-center align-items-center" style="font-size: 15px;">
                    <div>
                        {{$t('select_payment_method.type_2')}}: 
                        <i>({{$t('select_payment_method.deposit')}} R${{this.pixPrice}})</i>
                    </div>
                </div>
                <!-- Botão à direita -->
                <div class="col-4 p-2 d-flex justify-content-center align-items-center">
                    <BButton variant="primary" class="btn" @click="pixLink" v-text="$t('select_payment_method.register')" />
                </div>
            </div>
        </div>

        <div
            style="font-size: 16px; margin: 10px;"
        >
            <router-link
                to="#"
                @click="handleShowTablePrice"
            >
                <a v-text="$t('home.take.price_table')" />
            </router-link>
        </div>        

        <b-form-group class="text-center mt-3 m-auto">
            <button @click="back" style='font-size: large;' class="btn btn-md">
                <span style="margin-right: 10px; text-decoration: underline;" v-text="$t('select_payment_method.back')" />
            </button>
        </b-form-group>
    </b-card>

</template>

<script>

import API from '@/services/api';
import handleError from '@/services/handleError'

export default {
    name: 'SelectPaymentMethod',
    emits: ['pixLink', 'creditCardLink', 'back', 'handleShowTablePrice'],
    data() {
        return {
            success_val: true,
            pixPrice: null,
            mobile_api_url: import.meta.env.VITE_MOBILE_API
        };
    },
    mounted() {
        this.getPlans()
    },
    methods: {
        creditCardLink() {
            this.$emit('creditCardLink')
        },
        pixLink() {
            this.$emit('pixLink')
        },
        back() {
            this.$emit('back')
        },
        async getPlans() {
            try {
                const response = await API.get(this.mobile_api_url + 'plan')
                if (response && response.data && response.data.pix_price) {
                this.pixPrice = parseFloat(response.data.pix_price).toFixed(2).replace('.', ',')
                }
            } catch (error) {
                handleError(error)
            }
        },
        handleShowTablePrice() {
            this.$emit('handleShowPriceTable')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';
</style>