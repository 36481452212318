export default function getEventsBigComponents(component) {
  switch (component) {
    case 'priceTable':
      return {
        close: () => this.scrollToPriceTable()
      }
    case 'creditCardRegistration':
      return {
        user: (user) => {
          this.user = user
          this.selectBigBoxComponent()
        },
        back: () => {
          this.selectBigBoxComponent('selectPaymentMethod')
        }
      }
    case 'pixRegistration':
      return {
        user: (user) => {
          this.user = user
          this.selectBigBoxComponent()
        },
        back: () => {
          this.selectBigBoxComponent('selectPaymentMethod')
        }
      }
    case 'pixPayment':
      return {
        cancel: async (reason) => {
          if (this.transactionId) {
            await this.saveInvalidTransaction(
              reason ? reason : `Retirada cancelado pelo usuário no pagamento pelo QR Code`, this.transactionId, this.token, false
            )
          } else {
            this.cleanTransactionVariables()
          }
          this.selectBigBoxComponent("selectPaymentMethod")
        },
        handleShowPriceTable: () => this.scrollToPriceTable(),
        pixPayed: async () => {
          this.pixPayed = true
          this.selectBigBoxComponent()
        }
      }
    case 'managePayment':
      return {
        addNewPaymentMethod: () =>  {
          this.showManagePayment = false
          this.scrollToRef('selectPaymentMethod')
          this.selectBigBoxComponent('selectPaymentMethod')
        },
        user: (user) => this.user = user,
        close: () => {
          this.showManagePayment = false
          this.scrollToRef()
          this.selectBigBoxComponent()
        }
      }
    case 'selectCableType':
      return {
        cableType: (cableType) => {
          this.cableType = cableType
          this.selectBigBoxComponent()
        },
        cableTypeUnavailable: async (cableType) => {
          const cableTypeName = cableType === 0 ?
           'Lightning' : cableType === 1 ?
           'Micro' : cableType === 2 ? 
           'USB-C' : 'indefinido'
          await this.saveInvalidTransaction(
            `Não existe bateria com o cabo ${cableTypeName} neste terminal`, this.transactionId, this.token, false
          )
          this.selectBigBoxComponent()
        },
        cableTypeToRemove: async (cableTypeToRemove) => this.cableTypeToRemove = cableTypeToRemove
      }
    case 'tokenSelection':
      return {
        token: (token) => (this.token = token),
        transactionId: (transactionId) => {
          this.transactionId = transactionId
        },
        pointName: (pointName) => {
          this.pointName = pointName
          this.selectBigBoxComponent()
        },
        takeType: (takeType) => {
          this.takeType = takeType
        },
        handleShowPriceTable: () => this.scrollToPriceTable(),
        cableTypeAvailable: cableTypeAvailable => this.cableTypeAvailable = cableTypeAvailable,
        cableType: (cableType) => {
          this.cableType = cableType
          this.selectBigBoxComponent()
        },
        cableTypeToRemove: (cableTypeToRemove) => {
          this.cableTypeToRemove = cableTypeToRemove
        }
      }
    case 'demonstration':
      return {
        handleShowDemonstration: (totalSlots, selectedSlot) => {
          this.totalSlots = totalSlots
          this.selectedSlot = selectedSlot
        }
      }
    case 'selectPaymentMethod':
      return {
        pixLink: () => this.selectBigBoxComponent('pixRegistration'),
        creditCardLink: () => this.selectBigBoxComponent('creditCardRegistration'),
        back: () => this.selectBigBoxComponent(null, true),
        handleShowPriceTable: () => this.scrollToPriceTable()
      }
    default:
      return console.log('Component not found')
  }
}
