import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function validateTerminalToken(token, cableType) {
    const endpoint = 'transaction/validation'

    try {
        const response = await API.post(endpoint, {
            token: token,
            cable_type: cableType,
            custom_link: null
        })
        return response
    } catch (error) {
        handleError(error)
    }
}
