import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default async function fingerPrint() {
  try {
    const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get()
    return result.visitorId
  } catch (error) {
    console.error('Error fetching UUID:', error)
    return null
  }
}