import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function getUser(position = null) {
  return new Promise(async (resolve, reject) => {
    let url
    if (position && position.lat && position.lng) {
      url = `customer/me/${position.lat}/${position.lng}`
    } else {
      url = 'customer/me'
    }

    try {
      const response = await API.get(url)
      
      if (!response || !response.data) {
        reject(new Error('No data in response'))
        return
      }

      resolve(response.data)
    } catch (error) {
      handleError(error)
      reject(error)
    }
  })
}
