<template>
  <div>
    <b-card class="select-card" :class="{
          'border-danger margin-error': success_val === false
        }">
      <!-- Image transition -->
      <div key="image" class="d-flex flex-column justify-content-center align-items-center">
        <p class="page-title h4 text-uppercase" v-text="$t('home.take.take_title') " />
        <p class="page-text fw-normal" v-text="$t('home.take.take_info')" />
      </div>
      <!-- Image transition -->
      <BContainer key="text" class="bv-example-row mt-2">
        <BRow>
          <BCol cols="4" class="cable-type">
            <img id="cable_lightning_button" class="cable-img"
              :class="{
                'blinking-border': cableTypeSelected === CABLE_TYPE_LIGHTNING,
                'transparent': !showAppleLightningCable
              }"
              src="@/assets/images/cables/apple_2.png" alt="imagem conector Apple Lightning"
              @click="showAppleLightningCable && selectCable(CABLE_TYPE_LIGHTNING)">
            <p class="h6 page-text">
              Apple Lightning
            </p>
          </BCol> 
          <BCol cols="4" class="cable-type">
            <img id="cable_micro_usb_button" class="cable-img"
              :class="{
                'blinking-border': cableTypeSelected === CABLE_TYPE_MICRO,
                'transparent': !showMicroUSBCable
              }"
              src="@/assets/images/cables/micro_2.png" alt="imagem conector Micro USB"
              @click="showMicroUSBCable && selectCable(CABLE_TYPE_MICRO)">
            <p class="h6 page-text">
              Micro USB
            </p>
          </BCol>
          <BCol cols="4" class="cable-type">
            <img id="cable_type_c_button" class="cable-img"
              :class="{ 
                'blinking-border': cableTypeSelected === CABLE_TYPE_USBC,
                'transparent': !showTypeCCable
              }" src="@/assets/images/cables/c_2.png"
              alt="imagem conector USB tipo C" @click="showTypeCCable && selectCable(CABLE_TYPE_USBC)">
            <p class="h6 page-text">
              USB-C
            </p>
          </BCol>
        </BRow>
      </BContainer>
      <BRow v-if="!success_val">
        <BCol>
          <div class="message-fail">
            <h5>{{ message }}</h5>
          </div>
        </BCol>
      </BRow>
    </b-card>
  </div>
</template>

<script>
import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USBC } from '@/constants.js'
import sendGtag from '@/services/sendGtags'

const GTAG_CABLE_TYPE = 'cable_type_selection'

const APPLE_LIGHTNING_MICRO_USB = 0
const APPLE_LIGHTNING_USB_C = 1

export default {
  name: 'SelectCableType',
  props: {
    userId: {
      type: Number,
      default: null
    },
    cableTypeAvailable: {
      type: Array,
      default: null
    },
    cableTypeToRemove: {
      type: Array,
      default: []
    }
  },
  emits: ['cableType'],
  data() {
    return {
      message: '',
      success_val: true,
      cableTypeSelected: null,
      isBlinking: false,
      CABLE_TYPE_LIGHTNING,
      CABLE_TYPE_MICRO,
      CABLE_TYPE_USBC,
      showAppleLightningCable: false,
      showMicroUSBCable: false,
      showTypeCCable: false,
      tempCableTypeToremove: null
    }
  },
  mounted() {
    this.tempCableTypeToRemove = this.cableTypeToRemove

    if (this.tempCableTypeToRemove.length === 0) {
      this.showAppleLightningCable = true
      this.showMicroUSBCable = true
      this.showTypeCCable = true
      return
    }

    if (this.tempCableTypeToRemove.includes(APPLE_LIGHTNING_MICRO_USB)) {
      this.showAppleLightningCable = true
      this.showMicroUSBCable = true
    }

    if (this.tempCableTypeToRemove.includes(APPLE_LIGHTNING_USB_C)) {
      this.showAppleLightningCable = true
      this.showTypeCCable = true
    }
  },
  methods: {
    selectCable(cable) {    
      this.cableTypeSelected = cable  
      let params = {
          cable_type: cable,
          user_id: this.userId
        }
      sendGtag(GTAG_CABLE_TYPE, params)
      setTimeout(() => {
        this.$emit('cableType', cable);
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.cable-img {
  padding: 3px;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.70;
}

.cable-type {
  text-align: center !important;
  font-size: 12px;
}

.blinking-border {
  border: 2px solid transparent;
  animation: blink 0.25s 2.5;
}

.transparent {
  opacity: 0.3;
}


@keyframes blink {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.7;
  }
}
.margin-error {
  margin-bottom: 35px !important;
  padding-bottom: 35px !important;
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  border-radius: 0 0 5px 5px;
}
</style>
