export default {
  rentProcessingStart() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.start.text')
    this.showPBoxSpinner = true
  },
  rentProcessingVerifyTerminal() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.verify.text')
    this.showPBoxSpinner = true
  },
  rentProcessingConfirmation() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.confirmation.text')
    this.showPBoxCancelButton = false
    this.pBoxCancelButtonText = this.$t('home.take.cancel')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.pBoxButtonConfirmation = async () => await this.campaignVerification()
    this.pBoxButtonCancel = async () => {
      const confirmation = confirm(this.$t('rent_processing.confirmation.cancel_confirmation'))
      if (!confirmation) {
        return
      }
      const pix_payment = Boolean(this.user.pix_identification && !this.user.default_card)
      if (pix_payment) {
        alert(this.$t('rent_processing.confirmation.cancel'))
      }

      this.removeFromMiniBoxComponentList('token')
      await this.saveInvalidTransaction(this.$t("Usuário não confirmou a retirada"), this.transactionId, this.token, true)
      this.handleClosePBox()      
    }
  },
  rentProcessingPaymentPermission() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('home.take.credit_reservation')
    this.showPBoxCancelButton = false
    this.pBoxCancelButtonText = this.$t('home.take.cancel')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.showCheckboxPBox = true
    this.pBoxCheckBoxText = this.$t('home.take.dont_ask')
    this.pBoxCheckBoxValue = () =>
      (this.creditCardReservationAlways = !this.creditCardReservationAlways)
    this.pBoxShowLink = true
    this.handleOpenTable = this.scrollToPriceTable
    this.pBoxButtonConfirmation = async () => await this.completeCreditReservation(true)
    this.pBoxButtonCancel = async () => await this.completeCreditReservation(false)

    this.showPriceTable = false
  },
  rentProcessingPayment() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.payment.text')
  },
  rentProcessing() {
    this.cleanPBoxProps()
    this.showDemonstration = true
  },
  rentProcessingError(error, removeTerminalToken=true) {
    this.cleanTransactionVariables(removeTerminalToken)
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = ''
    this.pBoxText = `<p><b>${this.$t('rent_processing.error.text')}</b>${error || ''}</p>`;
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.understand')
    this.pBoxButtonConfirmation = this.handleClosePBox
  },
  rentProcessingSuccess(message) {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = message
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.pBoxButtonConfirmation = this.handleClosePBox
  },
  rentProcessingCampaingPermission() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('home.take.campaign')
    this.showPBoxCancelButton = true
    this.pBoxCancelButtonText = this.$t('rent_processing.campaign.cancel_button')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('rent_processing.campaign.confirm_button')
    this.pBoxButtonConfirmation = async () => await this.completeCampaignPermission(true)
    this.pBoxButtonCancel = async () => await this.completeCampaignPermission(false)
  },
  rentProcessingPixPaymentConfirmed () {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('rent_processing.pix_confirmation.title')
    this.pBoxText = this.$t('rent_processing.pix_confirmation.text')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('rent_processing.pix_confirmation.confirm')
    this.pBoxButtonConfirmation = () => {}
  },
  rentProcessingPixPaymentReturned () {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = 
    this.pBoxText = 
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('rent_processing.pix_confirmation.back')
    this.pBoxButtonConfirmation = () => {}
  },
  cleanPBoxProps() {
    this.showPBoxCancelButton = false
    this.showDemonstration = false
    this.pBoxCancelButtonText = ''
    this.showPBoxConfirmButton = false
    this.pBoxConfirmButtonText = ''
    this.showCheckboxPBox = false
    this.pBoxCheckBoxText = ''
    this.showPBoxSpinner = false
    this.pBoxSecondsToClose = 0
    this.pBoxShowLink = false
    this.handleOpenTable = null
    this.showTags = false
    this.pBoxCheckBoxValue = null
    this.pBoxButtonConfirmation = null
    this.pBoxButtonCancel = null
    this.pBoxClose = null
  },

  closePBox() {
    this.showPBox = false
    this.cleanPBoxProps()
  }
}
