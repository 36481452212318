<template>
  <div
    v-if="user && user.is_user_on_demand"
    class="select-card d-flex flex-column"
  >
    <div v-if="lastTransaction.on_free_time">
      <h6
        class="checkout-bold"
        v-text="$t('home.return.free_time')"
      />
      <p class="checkout">
        {{ lastTransaction.next_payment.cron }}
      </p>
    </div>
    <div
      v-if="!lastTransaction.on_free_time && (!campaign || (campaign && !campaign.no_payment))"
    >
      <h6
        class="checkout-bold"
        v-text="$t('home.return.time')"
      />
      <p class="checkout">
        {{ lastTransaction.cron_time }}
      </p>
      <h6
        class="checkout-bold"
        v-text="$t('home.return.price')"
      />
      <p class="checkout">
        R${{ lastTransaction.price_in_the_moment.reais }},{{
          lastTransaction.price_in_the_moment.centavos
        }}
      </p>
    </div>
    <div v-if="!lastTransaction.on_free_time && campaign && campaign.no_payment">
      <h6
        class="checkout"
        v-text="$t('home.return.free_time_over')"
      />
    </div>
  </div>

  <div class="accordion select-card d-flex flex-column mb-6" id="faqAccordion">
    <h6 class="text-center mb-4 mt-4" v-text="$t('home.return.faqs.title')"></h6>
    <div class="container mb-4">
      <div
        class="accordion-item w-70"
        v-for="(item, index) in faqItems"
        :key="index"
      >
        <h2 class="accordion-header" :id="'heading' + index">
          <button
            class="accordion-button"
            :class="{'collapsed': activeIndex !== index}"
            @click="toggleAccordion(index)"
            type="button"
          >
            {{ item.title }}
          </button>
        </h2>
        
        <transition name="accordion-transition">
          <div
            v-show="activeIndex === index"
            :id="'collapse' + index"
            class="accordion-collapse"
            :aria-labelledby="'heading' + index"
          >
            <div class="accordion-body">
              <template v-if="item.type === 'html'">
                <!-- Modificando aqui para usar @click diretamente -->
                <div v-html="item.content" @click="handleClick"></div>
              </template>
              <template v-else-if="item.type === 'steps'">
                <ol>
                  <li v-for="(step, stepIndex) in item.steps" :key="stepIndex">
                    {{ step }}
                  </li>
                </ol>
              </template>
              <template v-else>
                <p>{{ item.content }}</p>
              </template>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RentCheckout',
  props: {
    user: {
      type: Object,
      default: null
    },
    lastTransaction: {
      type: Object,
      default: null
    },
    campaign: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeIndex: null, 
      faqItems: [
        {
          title: this.$t("home.return.faqs.were_to_return.title"),
          type: "html",
          content: this.$t("home.return.faqs.were_to_return.html"),
        },
        {
          title: this.$t("home.return.faqs.how_to_return.title"),
          type: "steps",
          steps: [
            this.$t("home.return.faqs.how_to_return.step_1"),
            this.$t("home.return.faqs.how_to_return.step_2"),
            this.$t("home.return.faqs.how_to_return.step_3"),
            this.$t("home.return.faqs.how_to_return.step_4"),
            this.$t("home.return.faqs.how_to_return.step_5"),
          ],
        },
        {
          title: this.$t("home.return.faqs.return_failed.title"),
          type: "html",
          content: this.$t("home.return.faqs.return_failed.html"),
        },
      ],
    };
  },
  methods: {
    goToMaps() {
      this.$router.push('/maps');
    },
    handleClick(event) {
      if (event.target.tagName === 'A' && event.target.href.includes('javascript')) {
        this.goToMaps();
      }
    },
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

#cron-card {
  width: 80%;
  margin: 30px auto;
  border: 1px solid $color-energy-orange;
  background-color: transparent;
}

.checkout-bold {
  font-weight: 700;
  margin: 25px 0 5px;
  text-align: center;
}

.checkout {
  margin: 10px 0 30px;
  text-align: center;
}

.accordion-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.accordion-button:focus,
.accordion-button:active,
.accordion-button:focus-visible {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.accordion-button.collapsed {
  background-color: transparent;
}

/* Animação de Acordeão */
.accordion-collapse {
  overflow: hidden;
}

.accordion-collapse-enter-active,
.accordion-collapse-leave-active {
  transition: max-height 0.3s ease;
}

.accordion-collapse-enter, .accordion-collapse-leave-to {
  max-height: 0;
  padding: 0;
}
</style>