import axios from 'axios'

export default async function getPlans() {
  try {
    const response = await axios.get(import.meta.env.VITE_MOBILE_API + 'plans')
    if (response.data) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}