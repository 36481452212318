import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function deletePixIdentification (user) {
    try {
        const response = await API.delete('customer/pix-identification')
            if (response && response.data && response.data.success) {
                delete user.pix_identification
                return user
            }
            
            return false
    } catch (error) {
        handleError(error)
    }
}