export default async function getLocation() {
  return new Promise((resolve, reject) => {
    if (!('geolocation' in navigator)) {
      return reject(new Error('Geolocation not supported'))
    }

    const getLocationOptions = {
      enableHighAccuracy: true,
      timeout: 2000,
      maximumAge: 0
    }

    const onSuccess = (pos) => {
      const crd = pos.coords

      const position = {
        lng: crd.longitude,
        lat: crd.latitude,
        precision: crd.accuracy // "1"
      }
      resolve(position)
    }

    const onError = (err) => {
      console.log(`ERROR(${err.code}): ${err.message}`)
      reject(err)
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, getLocationOptions)
  })
}