<template>

  <div
    class="select-card text-center"
    :class="{ 
      'border-danger': showErrorMessage
    }"
  >
    <h5
      class="page-title"
      v-text="$t('pix_payment.title')"
    />


    <div v-if="loading">
      <h6 
        class="mt-3"
        v-text="$t('pix_payment.loading')"
      />
      <b-spinner id="loading-spinner" />
    </div>

    <div v-if="!loading && amount && amount.unit * 100 + amount.cents > 0">
      <p class="page-text">
        <span v-text="$t('pix_payment.text_1')" />
        <span>
          <b>
            {{ ` R$${this.amount.unit},${amount.cents} ` }}
          </b>
        </span>
        <span v-text="$t('pix_payment.text_2')" />
      </p>
      <p
        class="page-text"
        style="margin-bottom: 0;"
      >
        <span v-text="$t('pix_payment.refund')" />

        <span>
          <a
            @:click="handleShowTablePrice"
            v-text="$t('pix_payment.price_table')"
            class="default-link"
          />
        </span> 
        <span>
          .
        </span>
      </p>
    </div>
    <input
      v-if="showQRCodeAndPixPayment"
      type="text" 
      v-model="copyPastePix" 
      readonly
      class="my-3 selectable-input"
    />

    <div v-if="showQRCodeAndPixPayment">
      <button
        class="btn btn-md btn-pix-copy-paste"
        @click="copyToClipboard"
      >
        <span
          style="margin-right: 15px;"
          v-text="buttonText"
        />
        <font-awesome-icon class="fa-xl" icon="fa-copy" />
      </button>

      <span 
        class="alert alert-warning d-block my-3" 
        v-html="$t('pix_payment.warning')" 
        role="alert">
      </span>

    </div>
    <div v-else-if="showButtonTryAgain">
      <div
        class="text-center mt-2"
      >
        <button
          style='font-size: large;'
          class="btn btn-md"
          @click="makePixPaymentNewTry"
        >
          <u>
            <span
            style="margin-right: 10px; margin-bottom: 40px;"
            v-text="$t('pix_payment.try_again')"
          />
          </u>
          <font-awesome-icon
            class="fa-xl btn-pix"
            icon="fa-brands fa-pix"
          />
        </button>
      </div>
    </div>
    <img
      id="image"
      alt="Generated Image"
      :class="{
        'image-qrcode-none': !showQRCodeAndPixPayment,
        'image-qrcode': showQRCodeAndPixPayment
      }"
    >

    <div v-if="loadingPixPrice && !showQRCodeAndPixPayment" class="mt-4">
      <button v-text="$t('pix_payment.create_pix')" class="btn btn-primary" @click="makePixPayment" />
    </div>

    <BRow>
      <BCol
      >
        <b-form-group 
        class="text-center mt-2"
        :class="{
          'error-padding': showErrorMessage
        }">
          <button 
            @click="handleCancel"
            style='font-size: large;'
            :class="{ 
              'btn btn-md': !showErrorMessage,
              'btn btn-md mb-4': showErrorMessage
            }"
          >
            <u>
              <span
                style="margin-bottom: 40px;"
                v-text="$t('pix_payment.cancel')"
              />
            </u>
          </button>
        </b-form-group>
      </BCol>
    </BRow>
    <BRow v-if="showErrorMessage">
      <BCol>
        <div class="message-fail">
          <h5>{{ errorMessage }}</h5>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>

import API from '@/services/api'
import handleError from '@/services/handleError'
import axios from 'axios'

const PENDING = 10
const APPROVED = 2

export default {
  name: 'PixPayment',
  props: {
    transactionId: {
      type: Number,
      required: false
    },
    cableType: {
      type: Number,
      required: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['cancel', 'handleShowPriceTable', 'pixPayed'],
  data() {
    return {
      buttonCreatePix: 'Gerar QR Code',
      pixCreated: false,
      buttonTextInit: 'Pix Copia e Cola',
      buttonText: '',
      copyPastePix: null,
      base64Code: null,
      loading: false,
      loadingPixPrice: false,
      amount: {
        unit: null,
        cents: null
      },
      errorMessage: '',
      showButtonTryAgain: false,
      showQRCodeAndPixPayment: false,
      showErrorMessage: false,
      paymentId: null,
      timeoutId: null
    }
  },
  async mounted() {
    this.buttonText = this.buttonTextInit
    await this.getPixPrice()
  },
  methods: {
    copyToClipboard() {
      console.log("copy to clipboard");
      
      navigator.clipboard.writeText(this.copyPastePix)
        .then(() => {
          this.buttonText = "Copiado!"

          setTimeout(() => {
            this.buttonText = this.buttonTextInit
          }, 2000)
        })
        .catch(err => {
          console.error("Failed to copy: ", err)
        });
    },
    async getPixPrice() {
      this.loadingPixPrice = true
      try {
        const response = await API.get(`/plan`)
        const { pix_price } = response.data
        this.setPixPrice(pix_price)
      } catch (error) {
        handleError(error)
      }
    },
    async makePixPayment() {
      this.loading = true
      this.showQRCodeAndPixPayment = false
      this.showErrorMessage = false
      this.errorMessage = ''

      const retreiveQrCode = Boolean(
        this.user.waiting_for_pix_payment &&
        this.user.waiting_for_pix_payment.pix_data &&
        this.user.waiting_for_pix_payment.pix_data.qr_code &&
        this.user.waiting_for_pix_payment.pix_data.qr_code.image &&
        this.user.waiting_for_pix_payment.pix_data.qr_code.code &&
        this.user.waiting_for_pix_payment.pix_data.amount !== null &&
        this.user.waiting_for_pix_payment.pix_data.amount !== undefined
      )

      if (retreiveQrCode) {
        this.pixVariables(this.user.waiting_for_pix_payment.pix_data)
      } else {
        const isValid = await this.createPixPayment()
        if (!isValid) {
          this.loading = false
          this.showButtonTryAgain = true
          return
        }
      }

      this.timeoutId = null
      await this.startGetPixStatus()
    },
    async createPixPayment () {
      try {
        const response = await API.post('payment/pix', {
          transaction_id: this.transactionId
        })

        if (response.data && response.data.success && response.data.status === PENDING) {

          this.pixVariables(response.data)

          try {
            await API.post('transaction/validation/pix', {
              cable_type: this.cableType,
              transaction_id: this.transactionId
            })
          } catch (error) {
            handleError(error)
          }

          return true
          
        } else if (response.data && !response.data.success && response.data.message_code) {
          this.handleErrorMessage(response.data.message_code)
          return false
        } else {
          this.handleErrorMessage("Error ao gerar QR Code")
          handleError(error)
          return false
        }
      } catch (error) {
        this.handleErrorMessage("Error ao gerar QR Code")
        handleError(error)
        return false
      }
    },
    makePixPaymentNewTry() {
      this.showButtonTryAgain = false
      this.makePixPayment()
    },

    setPixPrice(price) {
      const [unit, cents] = parseFloat(price).toFixed(2).split('.')
      this.amount = {
        unit: unit.toString(),
        cents: cents.toString()
      }
    },
    pixVariables (data) {
      if (data.qr_code.image) {
        this.base64Code = data.qr_code.image
        this.showQrCode()
        this.showQRCodeAndPixPayment = true
      }
      if (data.qr_code.code) {
        this.copyPastePix = data.qr_code.code
        this.showQRCodeAndPixPayment = true
      }

      if (data.amount && data.amount > 0) {
        const [unit, cents] = parseFloat(data.amount).toFixed(2).split('.')
        this.amount = {
          unit: unit.toString(),
          cents: cents.toString()
        }
        this.showQRCodeAndPixPayment = true
      }

      this.paymentId = data.payment_id
    },
    showQrCode() {
      const base64Image = `data:image/png;base64,${this.base64Code}`
      const imgElement = document.getElementById('image')
      if (imgElement) {
        imgElement.src = base64Image
      }
      this.loading = false
    },
    handleErrorMessage(message) {
      this.errorMessage = message + ". Tente novamente ou utilize a opção Cartão de Crédito em: 'Forma de pagamento'."
      this.showErrorMessage = true
      setTimeout(() => {
        this.errorMessage = ''
        this.showErrorMessage = false
      }, 5000)
    },
    handleShowTablePrice() {
      this.$emit('handleShowPriceTable')
    },
    async getPixStatus() {
      if (!this.paymentId || !this.timeoutId) {
        this.cancelTimeout()
        return
      }
      try {
        const response = await API.get(`payment/status?pre_payment=${this.paymentId}`)

        if (!response || (response && !response.data)) {
          console.log("Não foi possível obter o status do pagamento")
          this.startGetPixStatus()
          return
        }
        const payed = response.data && response.data.success && response.data.status === APPROVED
        const pending = response.data && response.data.success && response.data.status === PENDING
        if (pending) {
          console.log("Aguardando pagamento")
        } else if (payed) {
          console.log("Pagamento efetuado")
          this.cancelTimeout()
          this.$emit('pixPayed', true)
          return
        } else {
          handleError(error)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.code === "ECONNABORTED") {
            console.error("Timeout error:", error.message);
            handleError({
              status: 408,
              response,
              message: "Timeout verificado PIX: "+this.paymentId})
          }
        }
        handleError(error)
      }
      this.startGetPixStatus()
    },
    async startGetPixStatus() {
      this.timeoutId = setTimeout(async () => {
        await this.getPixStatus()
      }, 3000)
    },
    cancelTimeout() {
      if (this.timeoutId) {
        clearTimeout()
        this.timeoutId = null
      }
    },
    async handleCancel() {
      this.cancelTimeout()
      this.$emit('cancel', "O pagamento pelo QR Code foi cancelado")
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.btn-pix-copy-paste {
  margin-bottom: 20px;
  background-color: $color-energy-blue !important;
  color: white !important;
}

.image-qrcode {
  border: 3px solid $color-energy-blue !important;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  min-width: 60%;
}

.image-qrcode-none {
  display: none;
}

#loading-spinner {
  display: block;
  margin: 20px auto;
  color: $color-energy-orange;
}

.display-none {
  display: none;
}

.btn-pix {
  color: $color-energy-blue !important;
  background-color: white !important;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  box-sizing: border-box;
  margin-top: 0.5rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  border-radius: 0 0 5px 5px !important;
}

.border-danger {
  border: 2px solid red;
}

.error-padding {
  padding: 10px 10px 50px 10px !important;
}

.selectable-input {
  font-size: large;
  padding: 5px;
  user-select: text;
  cursor: text;
}

.default-link {
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
}

.default-link:hover {
  color: #003399;
}

</style>
