// /* global fbq */
import API from '@/services/api'
import SelectCableType from '@/components/SelectCableType.vue'
import TokenSelection from '@/components/TokenSelection.vue'
import CreditCardRegistration from '@/components/CreditCardRegistration.vue'
import DefaultTile from '@/components/DefaultTile.vue'
import UserDefaults from '@/components/UserDefaults.vue'
import SideBar from '@/components/SideBar.vue'
import EvaluationBox from '@/components/EvaluationBox.vue'
import PriceTable from '@/components/PriceTable.vue'
import RentCheckout from '@/components/RentCheckout.vue'
import RentSuccess from '@/components/RentSuccess.vue'
import getPropsBigComponents from '@/views/home/service/bigBoxProps'
import getEventsBigComponents from '@/views/home/service/bigBoxEvents'
import getPropsMiniComponents from './service/miniBoxProps'
import getEventsMiniComponents from './service/miniBoxEvents'
import rentProcessingMethods from './service/rentProcessingMethods'
import CheckOutScreen from '@/views/checkout-screen/CheckOutScreen.vue'
import * as Sentry from '@sentry/vue'
import sendGtag from '@/services/sendGtags'
import fingerPrint from '@/services/fingerPrint.js'
import getLocation from '@/views/home/service/getLocation.js'
import getLastTransaction from '@/views/home/service/updateLastTransaction.js'
import getUser from '@/views/home/service/getUser.js'
import getNearPointInARange from '@/views/home/service/getNearPoint.js'
import saveBatteryStatus from '@/views/home/service/saveBatteryStatus.js'
import getPlans from './service/getPlans.js'
import SelectPaymentMethod from '@/components/SelectPaymentMethod.vue'
import setupOneSignal from './service/oneSignal.js'
import {
  TAKE_BATTERY_SUCCESS,
  TAKE_BATTERY_ERROR,
  BIG_BOX_COMPONENT_LIST,
  MINI_BOX_COMPONENT_LIST
} from '@/constants.js'


import '@/main.scss'
import Demonstration from '@/components/Demonstration.vue'
import PixRegistration from '@/components/PixRegistration.vue'
import PixPayment from '@/components/PixPayment.vue'
import ManagePayment from '@/components/ManagePayment.vue'
import handleError from '@/services/handleError'
import sendDeviceInfo from '@/views/home/service/sendDeviceInfo'
import * as amplitude from '@amplitude/analytics-browser';

const USER_INFO_SHARING_PERMISSION_DENIED = 1
const USER_INFO_SHARING_PERMISSION_GRANTED = 2
const USER_INFO_SHARING_PERMISSION_REFUSED = 3

const CREDIT_CARD_RESERVATION_DO_NOT_ALLOW = 1
const CREDIT_CARD_RESERVATION_ALLOW = 2
const CREDIT_CARD_RESERVATION_ALLOW_ALWAYS = 3

const PAYMENT_TIMEOUTED = 17

const GTAG_PRICE_TABLE = 'open_price_table'
const GTAG_PERMISSION = 'user_permission'
const GTAG_TAKE_BATTERY_SUCCESS = 'take_battery_success'

const PIX_PAYED = 2
const PIX_PENDING = 10

const CREDIT_CARD = 1
const PIX = 3

export default {
  name: 'home',
  components: {
    SelectCableType,
    TokenSelection,
    Demonstration,
    CreditCardRegistration,
    SideBar,
    DefaultTile,
    EvaluationBox,
    PriceTable,
    UserDefaults,
    RentCheckout,
    RentSuccess,
    CheckOutScreen,
    PixRegistration,
    PixPayment,
    ManagePayment,
    SelectPaymentMethod
  },
  props: [],
  data() {
    return {
      campaign: null,
      cableType: null,
      user: null,
      plans: null,
      lastTransaction: null,
      position: null,
      terminal: {
        campaign: false,
        far: false
      },
      user_permission: {
        info_sharing: null,
        info_sharing_anyway: false,
        credit_reservation: null,
        credit_reservation_always: false
      },
      qr_code_on: false,
      takeType: 'terminal',
      modal_error: '',
      qr_type: null,
      batteryLevel: null,
      batteryCharging: null,
      batteryTimeRemaining: null,
      transactionId: null,
      token: '',
      infoSharing: false,
      creditCardReservationAlways: false,
      infoSharingAlways: false,
      showCreditCardRegistration: false,
      intervalId: null,
      showPBox: false,
      pBoxClose: null,
      pBoxTitle: null,
      pBoxText: null,
      showPBoxCancelButton: null,
      pBoxCancelButtonText: null,
      showPBoxConfirmButton: null,
      pBoxConfirmButtonText: null,
      showCheckboxPBox: null,
      pBoxCheckBoxText: null,
      showPBoxSpinner: null,
      pBoxCheckBoxValue: null,
      pBoxButtonConfirmation: null,
      pBoxButtonCancel: null,
      pBoxSecondsToClose: null,
      editCableType: true,
      editCreditCardSelection: true,
      editTokenSelection: true,
      showPriceTable: false,
      pBoxShowLink: false,
      showEvaluationTags: false,
      pointName: null,
      isFirstRequest: true,
      showSpinner: false,
      showCreditCardDeleteBox: false,
      showBigBoxComponent: true,
      bigBoxComponentList: BIG_BOX_COMPONENT_LIST,
      miniBoxComponentList: MINI_BOX_COMPONENT_LIST,
      selectedBigBoxComponent: null,
      showMiniBoxComponentList: [],
      mapArrowDown: true,
      showDemonstration: false,
      totalSlots: 0,
      selectedSlot: null,
      cableTypeAvailable: null,
      fingerPrintUUID: null,
      showManagePayment: false,
      pixPayed: false, // para identificar se o pix já foi pago,
      allowEdit: true,
      cableTypeToRemove: [],
      persistentData:null
    }
  },
  beforeRouteLeave(to, from) {
    if (!['/history', '/maps', '/home'].includes(to.path)) {
      return
    }
    this.persistentData = {
      token: this.token,
      pointName: this.pointName,
      cableType: this.cableType,
      transactionId: this.transactionId,
      cableTypeAvailable: this.cableTypeAvailable,
    }
    localStorage.setItem('persistentData', JSON.stringify(this.persistentData))
  },
  created() {
    Object.keys(rentProcessingMethods).forEach((method) => {
      this[method] = rentProcessingMethods[method].bind(this)
    })
  },
  async mounted() {
    let campaign = null;
    let fingerPrintUUID = null;
    let position = null;
    let user = null;

    try {
      campaign = sessionStorage.getItem('campaign');
      this.token = localStorage.getItem('terminal_token') || '';
      this.pointName = localStorage.getItem('point_name');
      localStorage.removeItem('point_id');
    } catch (error) {
      Sentry.captureException(`Fail to access storage: ${error}`);
    }

    if (campaign) {
      this.campaign = campaign;
    }

    if (this.$route.query.closeMap === 'close') {
      this.mapArrowDown = true;
    }

    try {
      fingerPrintUUID = await fingerPrint();
      sendDeviceInfo(fingerPrintUUID);
    } catch (error) {
      console.error("Failed to get fingerprint:", error);
    }

    try {
      position = await getLocation();
      this.position = position;
    } catch (error) {
      console.error("Failed to get location:", error);
    }

    try {
      user = await getUser();
      this.user = user;
    } catch (error) {
      console.error("Failed to get user:", error);
      return;
    }

    if (!user) return;

    try {
      const identify = new amplitude.Identify()
      identify.set('email', user.email);
      identify.set('time_zone', 'America/Sao_Paulo');
      amplitude.identify(identify);
      amplitude.setUserId(user.email);
    } catch (error) {
      console.error("Failed to identify user:", error);
    }


    try {
      OneSignalDeferred.push(() => setupOneSignal(this.user.email));
    } catch (error) {
      console.error("Failed to setup OneSignal:", error);
      Sentry.captureException(`OneSignal setup failed: ${error}`);
    }

    this.loadPersistentDataFromRouting()
    this.verifyOpenPixPayment(user)

    try {
      if (position && position.lng && position.lat) {
        const point = await getNearPointInARange(position);
        if (point && point[0]) {
          sessionStorage.setItem('point_id_geolocation', point[0].id);
        }
      }

      if (fingerPrintUUID) {
        this.fingerPrintUUID = fingerPrintUUID;
        await saveBatteryStatus(user, position, fingerPrintUUID, this.cableType);
      }

      this.lastTransaction = await getLastTransaction();
      setInterval(async () => {
        let lastTransaction = await getLastTransaction();
        if (lastTransaction === null || lastTransaction === undefined || lastTransaction === '' || lastTransaction === NaN) { 
          return
        }
        this.lastTransaction = lastTransaction;
      }, 60000)

      this.plans = await getPlans();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed during final data load:", error);
    }

    this.selectBigBoxComponent();
  },
  watch: {
    showPBox(newValue) {
      this.editCableType = !newValue
      this.editCreditCardSelection = !newValue
      this.editTokenSelection = !newValue
    },
    selectedBigBoxComponent(newValue) {
      this.selectedBigBoxComponent = newValue
    }
  },

  methods: {
    getPropsBigComponents(component) {
      return getPropsBigComponents.call(this, component)
    },
    getEventsBigComponents(component) {
      return getEventsBigComponents.call(this, component)
    },
    getPropsMiniComponents(component) {
      return getPropsMiniComponents.call(this, component)
    },
    getEventsMiniComponents(component) {
      return getEventsMiniComponents.call(this, component)
    },
    addToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index === -1) {
        const componentToAdd = this.miniBoxComponentList.find((c) => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    removeFromMiniBoxComponentList(component, onlyRemove = false) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index !== -1) {
        this.showMiniBoxComponentList.splice(index, 1)
      }
    },
    addOrRemoveToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex((c) => c.reference === component)

      if (index !== -1) {
        this.showMiniBoxComponentList.splice(index, 1)
      } else {
        const componentToAdd = this.miniBoxComponentList.find((c) => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    findBigBoxComponent(component) {
      return this.bigBoxComponentList.find((bigBoxComponent) => bigBoxComponent.reference === component)
    },
    selectMiniBoxComponent() {

      if (this.cableType !== null && this.cableType !== undefined) {
        this.addToMiniBoxComponentList('cableType')
      } else {
        this.removeFromMiniBoxComponentList('cableType')
      }

      if (this.token && this.pointName) {
        this.addToMiniBoxComponentList('token')
      } else {
        this.removeFromMiniBoxComponentList('token')
      }
    },
    async selectBigBoxComponent(component = null, cleanRentVariables = false, totalCleanRentVariables = false) {

      if (cleanRentVariables) {
        this.cleanTransactionVariables(totalCleanRentVariables)
      }
      
      if (!component) {

        this.selectMiniBoxComponent()

        const cableTypeSelected = this.cableType !== null && this.cableType !== undefined

        if (!cableTypeSelected) {
          this.allowEdit = true
          this.selectedBigBoxComponent = this.findBigBoxComponent('selectCableType')
          return
        }

        if (cableTypeSelected && !this.token || !this.transactionId) {
          this.allowEdit = true
          this.selectedBigBoxComponent = this.findBigBoxComponent('tokenSelection')
          return
        }

        if (this.token && this.pointName) {
          await saveBatteryStatus(
            this.user, this.position, this.fingerPrintUUID, this.cableType
          )
        }

        if (!this.user.default_card && !this.user.pix_identification) {
          this.allowEdit = true
          this.cableTypeToRemove = []
          this.selectedBigBoxComponent = this.findBigBoxComponent('selectPaymentMethod')
          return
        }

        if (
          !this.pixPayed &&
          this.transactionId &&
          this.user.pix_identification &&
          !this.user.default_card
        ) {
          this.allowEdit = false
          this.cableTypeToRemove = []
          this.selectedBigBoxComponent = this.findBigBoxComponent('pixPayment')
          return
        }

        const paymentCreditCard = this.user.default_card
        const paymentNotApplied = !this.user.is_user_on_demand
        const paymentPix = this.user.pix_identification && this.pixPayed
        const tokenSelected = this.token && this.pointName
        const transactionId = this.transactionId
        const payment = paymentCreditCard || paymentNotApplied || paymentPix

        if (payment && cableTypeSelected && tokenSelected && transactionId) {
          this.pixPayed = false
          this.cableTypeToRemove = []
          this.selectedBigBoxComponent = null
          await this.completeVerifyTerminal(this.position)
          return
        }

        return
      }
      this.selectedBigBoxComponent = this.findBigBoxComponent(component)
    },
    handleClosePBox() {
      this.showPBox = false
      this.selectBigBoxComponent(null, true, false)
    },
    loadPersistentDataFromRouting () {
      let persistentData = localStorage.getItem('persistentData')
      localStorage.removeItem('persistentData')
      persistentData = persistentData ? JSON.parse(persistentData) : null
      if (persistentData) {
        this.token = persistentData.token
        this.pointName = persistentData.pointName
        this.cableType = persistentData.cableType
        this.transactionId = persistentData.transactionId
        this.cableTypeAvailable = persistentData.cableTypeAvailable
      }
    },
    verifyOpenPixPayment(user) {
      const isLastTransactionWaitingForPixPayment = Boolean (
        user.waiting_for_pix_payment &&
        "payed" in user.waiting_for_pix_payment &&
        [PIX_PAYED, PIX_PENDING].includes(user.waiting_for_pix_payment.payed) &&
        user.waiting_for_pix_payment.transaction_id &&
        user.waiting_for_pix_payment.token &&
        user.waiting_for_pix_payment.point_name &&
        user.waiting_for_pix_payment.cable_type !== null &&
        user.waiting_for_pix_payment.cable_type !== undefined
      )
      if (isLastTransactionWaitingForPixPayment) {
        // todo: verificar de validade do pix em relação a data de criação
        const open_pix_transaction = user.waiting_for_pix_payment
        this.pixPayed = open_pix_transaction.payed == PIX_PAYED ?
        true : false
        this.transactionId = open_pix_transaction.transaction_id
        this.token = open_pix_transaction.token
        this.pointName = open_pix_transaction.point_name
        this.cableType = open_pix_transaction.cable_type
      }
    },
    scrollToPriceTable() {
      if (!this.showPriceTable) {
        let params = {}
        if (this.user.id) {
          params['user_id'] = this.user.id
        }
        if (this.transactionId) {
          params['transaction_id'] = this.transactionId
        }
        params['link_source'] = 'home'
        sendGtag(GTAG_PRICE_TABLE, params, true)
      }

      this.showPriceTable = !this.showPriceTable
      const element = this.$refs['priceTable']
      if (element) {
        this.$scrollTo(element, 500, {
          easing: 'ease'
        })
      }
    },
    scrollToRef(boxRef) {
      const element = this.$refs[boxRef]
      if (element) {
        this.$scrollTo(element, 500, {
          easing: 'ease'
        })
      } else {
        this.$scrollTo(0, 500, {
          easing: 'ease'
        })
      }
    },
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    getModel(info) {
      let model = ''
      if (info.isSamsung) {
        model = 'samsung'
      } else if (info.isiPhone) {
        model = 'apple'
      } else if (info.isiPad) {
        model = 'apple'
      } else if (info.isLinux) {
        model = 'linux'
      }
      return model
    },
    takeBattery: async function () {
      try {
        const response = await this.takeValidatedBattery()
        this.showPBox = false

        if (response && response.data.success) {
          this.selectedSlot = response.data.selected_slot
          this.totalSlots = response.data.terminal.total_slots
          this.showDemonstration = true
          this.transactionId = response.data.transaction_id
          await this.getTransactionStatus(this.transactionId)
        } else {
          this.showDemonstration = false
          if (response && response.data && response.data.error) {
            if (
              response.data.status && response.data.status === PAYMENT_TIMEOUTED
            ) {
              this.rentProcessingError(': ' + response.data.error, false)
              return
            }
            this.rentProcessingError(': ' + response.data.error)
            return
          } else {
            this.rentProcessingError(': ' + 'Falha ao retirar bateria')
          }
        }
      } catch (error) {
        this.showDemonstration = false
        this.rentProcessingError(': ' + 'Falha ao retirar bateria')
        this.handleError(error)
      }
    },
    async takeValidatedBattery() {
      const params = {
        transaction_id: this.transactionId,
        uuid: this.UUID,
        cable_type: this.cableType,
        payment_method: this.paymentMethod(),
      }

      try {
        const url = 'transaction/validation/take_battery'
        return await API.post(url, params)
      } catch (error) {
        handleError(error)
      }
    },

    async saveInvalidTransaction(cancelReason, transactionId = null, tokenS = null, removeTerminalToken = true) {
      let params = {
        token: this.token ? this.token : tokenS,
        cancel_reason: cancelReason.length > 400 ? cancelReason.substring(0, 400) : cancelReason,
        cable_type: this.cableType
      }

      params.transaction_id = transactionId || this.transactionId;

      try {
        let endpoint = 'transaction/invalid'
        return await API.post(endpoint, params)
      } catch (error) {
        console.log(error)
      } finally {
        this.cleanTransactionVariables(removeTerminalToken)
      }
    },
    cleanTransactionVariables(removeTerminalToken = true) {
      this.transactionId = null
      this.cableTypeAvailable = null
      this.cableType = null
      this.removeFromMiniBoxComponentList('cableType')
      if (removeTerminalToken) {
        this.cableTypeToRemove = []
        this.token = null
        this.pointName = null
        localStorage.removeItem('terminal_token')
      }
    },

    getTransactionStatus: async function (id) {
      try {
        const response = await API.get('transaction/status/' + id)

        if (!response || (response && !response.data)) {
          this.rentProcessingError(': Falha ao processar a retirada')
          return
        }

        if (response.data.timeouted) {
          this.showEvaluationTags = true
          return
        }

        if (!response.data.success && response.data.message) {
          this.rentProcessingError(': ' + response.data.message, false)
          return
        }
        if (response.data.status === TAKE_BATTERY_SUCCESS) {
          this.rentProcessingSuccess('Bateria retirada com sucesso')
          let params = {}
          if (this.user) {
            params['user_id'] = this.user.id
          }
          if (this.transactionId) {
            params['transaction_id'] = this.transactionId
          }
          sendGtag(GTAG_TAKE_BATTERY_SUCCESS, params)
          setTimeout(async () => {
            this.showDemonstration = false
            this.lastTransaction = await getLastTransaction()
          }, 2000)
          return
        } else if (response.data.status === TAKE_BATTERY_ERROR) {
          this.rentProcessingError(': Falha ao processar a retirada', false)
          return
        } else if (response.data.status === 'firmware_communication_error') {
          this.rentProcessingError(': Ocorreu um erro ao tentar se comunicar com o terminal')
        } else {
          setTimeout(async () => {
            await this.getTransactionStatus(id)
          }, 3000)
        }
      } catch (error) {
        console.log(error)
        this.rentProcessingError(': Falha ao processar a retirada')
        return
      }
    },
    resetRentVariables: function () {
      this.modal_error = ''
      this.terminal.campaign = false
      this.position = null
      this.user_permission.info_sharing = null
      this.user_permission.info_sharing_anyway = false
      this.user_permission.credit_reservation = null
      this.user_permission.credit_reservation_always = false
    },
    confirmRent() {
      this.resetRentVariables()

      if (
        this.campaign?.no_payment ||
        this.user.credit_cards.length > 0 ||
        !this.user.is_user_on_demand
      ) {
        this.verifyTerminal()
        return
      }
    },
    async verifyTerminal() {
      if (this.takeType !== 'terminal') {
        return this.campaignVerification()
      }

      if (!this.position) {
        try {
          const position = await getLocation()
          this.position = toRaw(position)
          console.log('geolocation: ', this.position)
        } catch (error) {
          console.error('Error on geolocation:', error)
        }
      }
      this.selectBigBoxComponent()
    },
    async completeVerifyTerminal(position) {
      this.rentProcessingVerifyTerminal()

      let params
      try {
        if (position && position.lng && position.lat) {
          params = {
            token: this.token,
            position: {
              lng: position.lng,
              lat: position.lat
            }
          }
        } else {
          params = {
            token: this.token
          }
        }

        const response = await API.post('transaction/check_terminal', params)
        this.terminal.campaign = response.data.campaign
        
        this.rentProcessingConfirmation()
      } catch (error) {
        handleError(error)
        this.rentProcessingError(": Falha ao verificar terminal")
      }
    },
    campaignVerification: async function () {
      this.rentProcessingPayment()
      if (this.campaing && this.campaign.no_payment) {
        return await this.campaignPermission()
      }

      if (
        this.user.user_permission.info_sharing_permission_granted ===
        USER_INFO_SHARING_PERMISSION_DENIED ||
        !this.user.is_user_on_demand
      ) {
        return await this.campaignPermission()
      }

      const creditCardPermissionNotNeeded = (
        this.paymentMethod() === CREDIT_CARD &&
        this.user.user_permission.transaction_permission_granted === CREDIT_CARD_RESERVATION_ALLOW_ALWAYS
      )
      if (creditCardPermissionNotNeeded) {
        return await this.takeBattery()
      }

      if (this.paymentMethod() === PIX) {
        return await this.campaignPermission()
      }

      this.rentProcessingPaymentPermission()
    },
    completeCreditReservation: async function (ok) {
      this.rentProcessingPayment()
      this.user_permission.credit_reservation_always = this.creditCardReservationAlways

      if (ok) {
        if (this.user_permission.credit_reservation_always) {
          this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_ALLOW_ALWAYS
        } else {
          this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_ALLOW
        }
        await this.campaignPermission()
      } else {
        this.user_permission.credit_reservation = CREDIT_CARD_RESERVATION_DO_NOT_ALLOW
        await this.sendUserPermissions()
      }
    },
    campaignPermission: async function () {
      if (this.terminal.campaign) {
        this.rentProcessingCampaingPermission()
      } else {
        await this.sendUserPermissions()
      }
    },
    completeCampaignPermission: async function (ok) {
      this.rentProcessingVerifyTerminal()
      if (ok) {
        const params = this.permissionTagManager()
        sendGtag(GTAG_PERMISSION, params)
        this.user_permission.info_sharing = USER_INFO_SHARING_PERMISSION_GRANTED
      } else {
        const params = this.permissionTagManager(null, false)
        sendGtag(GTAG_PERMISSION, params)
        this.user_permission.info_sharing = USER_INFO_SHARING_PERMISSION_REFUSED
      }
      await this.sendUserPermissions()
    },
    sendUserPermissions: async function () {
      try {
        await API.post('transaction/permission', {
          transaction_permission_granted: this.user_permission.credit_reservation,
          info_sharing_permission_granted: this.user_permission.info_sharing
        })
        if (this.user_permission.credit_reservation === CREDIT_CARD_RESERVATION_DO_NOT_ALLOW) {
          const params = this.permissionTagManager(null, false)
          sendGtag(GTAG_PERMISSION, params)
          await this.saveInvalidTransaction(null, this.transactionId, this.token, false)
          this.rentProcessingError(': ' + this.$t("home.take.error_procession"), false)
          return
        }
        const params = this.permissionTagManager()
        sendGtag(GTAG_PERMISSION, params)
        await this.takeBattery()
      } catch (error) {
        handleError(error)
        this.rentProcessingError(': Falha ao se comunicar com o servidor', false)
      }
    },
    paymentMethod () {
      if (this.user.default_card) {
        return CREDIT_CARD
      }
      if (this.user.pix_identification) {
        return PIX
      }
      return none
    },
    permissionTagManager(reservationPermission = true, freeTimePermission = true) {
      let params = {}
      if (this.user) {
        params['user_id'] = this.user.id
      }
      if (this.transactionId) {
        params['transaction_id'] = this.transactionId
      }
      if (this.position && this.position.lat && this.position.lng) {
        params['geolocation_permission'] = true
      } else {
        params['geolocation_permission'] = false
      }

      if (!reservationPermission) {
        params['reservation_permission'] = false
      } else if (!freeTimePermission) {
        params['reservation_permission'] = true
        params['free_time_permission'] = false
      } else {
        params['reservation_permission'] = true
        params['free_time_permission'] = true
      }
      return params
    },
    
}
}