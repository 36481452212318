export default function getPropsBigComponents(component) {
  switch (component) {
    case 'priceTable':
      return {
        showPriceTable: this.showPriceTable
      }
    case 'creditCardRegistration':
      return {
        user: this.user,
      }
    case 'pixRegistration':
      return {
        user: this.user
      }
    case 'pixPayment':
      return {
        transactionId: this.transactionId,
        user: this.user,
        cableType: this.cableType,
      }
    case 'managePayment':
      return {
        user: this.user
      }
    case 'selectCableType':
      return {
        userId: this.user.id,
        cableTypeAvailable: this.cableTypeAvailable,
        cableTypeToRemove: this.cableTypeToRemove
      }
    case 'tokenSelection':
      return {
        confirmButton: () => this.selectBigBoxComponent(),
        cableType: this.cableType,
        allowEdit: this.editTokenSelection,
        saveInvalidTransaction: this.saveInvalidTransaction,
        token: this.token,
        user: this.user
      }
    case 'selectPaymentMethod':
      return {}
    default:
      return console.log('Component not found: ' + component)
  }
}
