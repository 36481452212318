import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function createPixIdentification (user, pixData) {
    try {
        let response
        if (user.pix_identification) {
            response = await API.put('customer/pix-identification', pixData)
        } else {
            response = await API.post('customer/pix-identification', pixData)
        }
        
        if (response && response.data && response.data.success) {
            user.pix_identification = {
                name: pixData.name,
                cpf_or_cnpj: pixData.cpf_or_cnpj
            }
            delete user.default_card
            user.credit_cards.map(credit_card => {
                credit_card.default = false
                return credit_card
            })
            return user
        }
        return false
    } catch (error) {
        handleError(error)
    }
}