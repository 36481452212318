<div id="home-master-div" :style="{ background: 'white', color: '#424242' }">


  <SideBar :nome="user && user.name" :email="user &&user.email" />

  <div class="d-flex justify-content-center" v-if="!lastTransaction">
    <BSpinner class="loading-spinner mt-5" />
  </div>

  <CheckOutScreen v-if="user && lastTransaction && lastTransaction.last_operation === 'retirar'" :name="user.name"
    :email="user.email" :user="user" :lastTransaction="lastTransaction" :campaign="campaign" :cableType="cableType"
    :showPriceTable="showPriceTable" @scrollToPriceTable="scrollToPriceTable" />

  <div v-else-if="lastTransaction && lastTransaction.last_operation != 'retirar'" class="default-container">
    <div class="default-container-start">
      <EvaluationBox v-if="showEvaluationTags" :transactionId="transactionId" @close="() => {
          this.showEvaluationTags = false
          this.selectBigBoxComponent()
        }" />

      <DefaultTile
        :showDefaultTile="showPBox"
        :title="pBoxTitle"
        :text="pBoxText"
        :showCancelButton="showPBoxCancelButton" :cancelButtonText="pBoxCancelButtonText"
        :showConfirmButton="showPBoxConfirmButton" :confirmButtonText="pBoxConfirmButtonText"
        :showCheckBox="showCheckboxPBox"
        :checkBoxText="pBoxCheckBoxText"
        :showSpinner="showPBoxSpinner"
        :secondsToClose="pBoxSecondsToClose"
        :showLink="pBoxShowLink"
        @handleOpenTable="scrollToPriceTable"
        @checkBoxValue="pBoxCheckBoxValue"
        @confirm="pBoxButtonConfirmation"
        @cancel="pBoxButtonCancel"
        @close="handleClosePBox"
      >
        <template #text>
          <div v-html="pBoxText"></div>
        </template>
      </DefaultTile>
      <div :ref="selectedBigBoxComponent.reference" v-if="showBigBoxComponent && selectedBigBoxComponent">
        <component :is="selectedBigBoxComponent.name" v-bind="getPropsBigComponents(selectedBigBoxComponent.reference)"
          v-on="getEventsBigComponents(selectedBigBoxComponent.reference)" />
      </div>

      <Demonstration v-if="showDemonstration" :totalSlots="totalSlots" :selectedSlot="selectedSlot" />

    </div>

    <div class="default-container-end">

      <!-- Table Price -->
      <UserDefaults
        v-if="!showPriceTable"
        :subText="$t('home.take.price_table')"
        :arrowDown="!showPriceTable"
        @clicked="scrollToPriceTable"
      >
        <template #left-content>
          <font-awesome-icon class="fa-3x" icon="money-bill" />
        </template>
      </UserDefaults>
      <div ref="priceTable">
        <PriceTable
          v-if="showPriceTable"
          class="select-card"
          style="margin-bottom: 1% !important;"
          :showPriceTable="showPriceTable"
          @close="scrollToPriceTable"
        />
      </div>

      <!-- Mini Box -->
      <component v-for="component in showMiniBoxComponentList" :key="component.id" :is="component.name" :ref="component.reference"
        v-bind="getPropsMiniComponents(component.reference)"
        v-on="getEventsMiniComponents(component.reference)">   

        <template #left-content>
          <img v-if="cableType === 0" class="cable-img" src="@/assets/images/cables/apple_2.png"
            alt="imagem conector USB tipo C" />
          <img v-if="cableType === 1" class="cable-img" src="@/assets/images/cables/micro_2.png"
            alt="imagem conector USB tipo C" />
          <img v-if="cableType === 2" class="cable-img" src="@/assets/images/cables/c_2.png"
            alt="imagem conector USB tipo C" />
        </template>

        <template #left-content v-if="component.reference === 'token'">
          <font-awesome-icon :style="{color: '#424242'}" class="fa-3x" icon="location-dot" />
        </template>

      </component>

      <!-- Management Payment -->
      <UserDefaults
        v-if="!showManagePayment"
        v-bind="getPropsMiniComponents('managePayment')"
        v-on="getEventsMiniComponents('managePayment')">
        <template #left-content>
          <font-awesome-icon v-if="user && user.default_card"
              class="fa-3x"
              icon="fa-credit-card"
            />
          <font-awesome-icon v-else-if="user && user.pix_identification" class="fa-3x" icon="fa-brands fa-pix" />
          <font-awesome-icon v-else class="fa-3x" icon="fa-dollar-sign" />
        </template>
      </UserDefaults> 
      <div
        ref="managePayment"
        class="select-card"
        :class="{ 'hide': !showManagePayment }"
        style="margin-bottom: 10px!important;"
      >
        <ManagePayment
          v-if="showManagePayment"
          user="user"
          v-bind="getPropsBigComponents('managePayment')"
          v-on="getEventsBigComponents('managePayment')"
        />
      </div>
      
      <!-- Map -->
      <UserDefaults
        v-bind="getPropsMiniComponents('map')"
        v-on="getEventsMiniComponents('map')">
        <template #left-content>
          <font-awesome-icon class="fa-3x" icon="map-location-dot" />
        </template>
      </UserDefaults>

    </div>
  </div>
</div>