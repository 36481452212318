<template>
  <router-view />
</template>

<script>
import axios from 'axios'
import API from '@/services/api'
import getPointByToken from '@/services/getPointName'
import * as Sentry from '@sentry/vue'
import sendGtag from '@/services/sendGtags'
import fingerPrint from '@/services/fingerPrint'

const GTAG_ACCESS_PUBLIC_PAGE = 'access_public_page'
const GTAG_ACCESS_PRIVATE_PAGE = 'access_private_page'

export default {
  name: 'app',
  data() {
    return {
      language: 'pt-BR',
      external: false,
      token: null,
      terminalToken: null,
      pointName: null,
      pathname: null,
      search: null,
      publicPages: ['/login-social', '/login-email', '/code-validation', '/sign-up', '/maps', '/logout'],
      privatePages: ['/home', '/history']
    }
  },

  async created() {
    try {
      const fingerPrintUUID = await fingerPrint()
      API.defaults.headers.common['X-Trace-ID'] = fingerPrintUUID
    } catch (error) {
      Sentry.captureException("X-Trace-ID on header", error)
    }

    this.pathname = window.location.pathname
    this.search = window.location.search

    if (this.search.includes('?token=')) {
      this.token = this.search.split('?token=')[1].split('&')[0]
    } else if (this.pathname.includes('qr/')) {
      this.terminalToken = this.pathname.split('qr/')[1]
      this.pathname = this.pathname.split('qr/')[0]
    }

    let point = null
    if (this.terminalToken) {
      point = await getPointByToken(this.terminalToken)
    }
    if (point && point.success) {
      this.pointName = point.name
      try {
        localStorage.setItem('terminal_token', this.terminalToken)
        localStorage.setItem('point_name', point.name)
        sessionStorage.setItem('point_id_qr', point.id)
        localStorage.setItem('point_id', point.id)
      } catch (error) {
        Sentry.captureException("Fail to access local storage with error: ", error)
      }
    }

    if (localStorage.getItem('token')) {
      try {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
      } catch (error) {
        Sentry.captureException("Fail to access local storage with error: ", error)
      }
      if (this.privatePages.includes(this.pathname)) {
        sendGtag(GTAG_ACCESS_PRIVATE_PAGE, {
            public_page: this.pathname
          }, true)
        return this.$router.push(this.pathname)
      } else {
        sendGtag(GTAG_ACCESS_PRIVATE_PAGE, {
            public_page: '/home'
          }, true)
        return this.$router.push('/home')
      }
    } 

    if (this.token) {
      axios.defaults.headers.common['Authorization'] = this.token
      return this.$router.push('/home')
    }
    
    if (this.publicPages.includes(this.pathname)) {
      sendGtag(GTAG_ACCESS_PUBLIC_PAGE, { public_page: this.pathname }, true)
      return this.$router.push(this.pathname)
    }

    sendGtag(GTAG_ACCESS_PUBLIC_PAGE, {
            public_page: '/login-social'
          }, true)
    this.$router.push('/login-social')
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: main;
  src: url('@/assets/fonts/main.otf');
}

html,
body {
  margin: 0 !important;
  font-family: main;
  letter-spacing: 1px;
}
</style>
