export default function getEventsMiniComponents(component) {
  switch (component) {
    case 'map':
      return {
        clicked: () => {
          this.mapArrowDown = false
          return this.$router.push({ name: 'maps' })
        }
      }
    case 'cableType':
      return {
        clicked: () => {
          this.cableType = null
          sessionStorage.removeItem('cableType')
          this.closePBox()
          return this.selectBigBoxComponent()
        }
      }
    case 'token':
      return {
        clicked: () => {
          this.transactionId = null
          this.pointName = null
          this.cableTypeAvailable = null
          this.token = null
          this.closePBox()
          localStorage.removeItem('terminal_token')
          return this.selectBigBoxComponent()
        }
      }
    case 'managePayment':
      return {
        clicked: () => {
          this.showManagePayment = !this.showManagePayment
        }
      }
    default:
      return console.log('Component not found')
  }
}
